import { ThunkActionType } from 'store/store';
import { Token } from 'enum/Token';
import {
  LOGIN_URL, VAULT_URL, getLoggedUserApi, logoutApi,
} from 'services/api/authApi';
import { handleApiError } from 'store/app/appThunk';
import { toDataURL } from 'utils/getBase64Image';
import { getAccessByRole } from 'utils/getAccessbyRole';
import {
  resetAuthState, setAvatar, setIsAuth, setServices, setUser,
} from './authReducer';

const SERVICE_TYPE_ID = 2;

export const redirectToLogin = (isHard?: boolean) => {
  const pathName = isHard ? window.location.origin : window.location.href;
  window.location.assign(`${LOGIN_URL}${pathName}`);
};

export const getUserData = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      const res = await getLoggedUserApi();

      if (res.avatar) {
        toDataURL(`${VAULT_URL}${res.avatar}`, (avatar) => dispatch(setAvatar(avatar)));
      }

      dispatch(setUser(res));

      const services = res.services?.filter(({ serviceTypeId }) => (
        serviceTypeId === SERVICE_TYPE_ID
      )).map((item) => {
        const account = res.accounts.find(({ id }) => id === +item.accountId);
        if (account) {
          return ({
            ...item,
            logo: account.logo,
            accountName: account.name,
            accountShortName: account.shortName,
            isAdmin: account.isAdmin,
            isOwner: account.isOwner,
            access: getAccessByRole(item.role),
          });
        }
        return item;
      });
      dispatch(setServices(services));
      dispatch(setIsAuth(true));
    } catch (error: any) {
      redirectToLogin();
    }
  };
};

export const resetAuth = (): ThunkActionType => async (dispatch) => {
  dispatch(resetAuthState());
};

export const logoutAccount = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      if (localStorage.getItem(Token.REFRESH_TOKEN)) {
        await logoutApi();

        localStorage.removeItem(Token.ACCESS_TOKEN);
        localStorage.removeItem(Token.REFRESH_TOKEN);
        dispatch(resetAuth());
        redirectToLogin(true);
      }
    } catch (err) {
      dispatch(handleApiError(err));
    }
  };
};
