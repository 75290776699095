import {
  Suspense, useCallback,
} from 'react';
import {
  Route, Routes, useNavigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { accessSelector } from 'store/auth/authSelectors';
import { ICustomRouterConfig } from 'interfaces/IRouterConfig';
import { RoutePath } from 'constants/RoutePath';
import { routerConfig } from './router-config';
import { AppLoader } from '../../shared/AppLoader/AppLoader';

const PrivateRoute = ({
  children, access, adminAccess,
}) => {
  const navigate = useNavigate();

  if (!adminAccess || adminAccess.includes(access)) {
    return children;
  }

  return navigate(RoutePath.ERROR);
};

const AppRouter = () => {
  const access = useSelector(accessSelector);

  const renderRoutes = useCallback((routerConfig: ICustomRouterConfig[]) => (
    routerConfig.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={
          (
            <PrivateRoute
              adminAccess={route.adminAccess}
              access={access}
            >
              {route.element}
            </PrivateRoute>
          )
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    ))
  ), [access]);

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        {renderRoutes(routerConfig)}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
